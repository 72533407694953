:root { 
--gray_600:#787878; 
--gray_400:#b9b9b9; 
--blue_gray_100:#cecece; 
--gray_600_01:#828282; 
--red_500:#ff3630; 
--black_900_3f:#0000003f; 
--amber_A200:#ffc83c; 
--gray_50:#f8f8f8; 
--black_900_33:#00000033; 
--black_900:#000000; 
--light_green_800:#4d9e00; 
--blue_gray_800:#17375c; 
--white_A700:#ffffff; 
}